import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { PipesCoreModule } from '@core/pipes/pipes-core.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BsmNodeItemComponent } from '@shared/business-structure/components/bsm-node-item/bsm-node-item.component';
import { BsmTreeGrpupComponent } from '@shared/business-structure/components/bsm-tree-group/bsm-tree-group.component';
import { BsmTreeItemComponent } from '@shared/business-structure/components/bsm-tree-item/bsm-tree-item.component';
import { BusinessStructureManagerComponent } from '@shared/business-structure/components/business-structure-manager/business-structure-manager.component';
import { BusinessUnitNavigationComponent } from '@shared/business-structure/components/business-unit-navigation/business-unit-navigation.component';
import { LeaveBusinessStructurePopupComponent } from '@shared/business-structure/components/leave-business-structure-popup/leave-business-structure-popup.component';
import { BusinessStructureService } from '@shared/business-structure/services/business-structure.service';
import { BusinessStructureEffects } from '@shared/business-structure/store/business-structure.effects';
import { BusinessStructureFacade } from '@shared/business-structure/store/business-structure.facade';
import { businessStructureReducer } from '@shared/business-structure/store/business-structure.reducer';
import { CommonSharedModule } from '@shared/common/common-shared.module';
import { GovernanceSharedModule } from '@shared/governance/governance.module';
import { NgxDropdownModule } from '@shared/ngxdropdown/ngxdropdown.module';
import { PageUtilsSharedModule } from '@shared/page-utils/page-utils-shared.module';
import { PopupsSharedModule } from '@shared/popups/popups-shared.module';
import { VScrollTreeComponent } from '@shared/vscroll-tree/components/vscroll-tree.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatRippleModule,
    MatTreeModule,
    MatIconModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    PageUtilsSharedModule,
    StoreModule.forFeature('business-structure', businessStructureReducer),
    EffectsModule.forFeature([BusinessStructureEffects]),
    NgxDropdownModule,
    PipesCoreModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    ScrollingModule,
    GovernanceSharedModule,
    ClipboardModule,
    MatChipsModule,
    PopupsSharedModule,
    OverlayModule,
    CommonSharedModule,
    VScrollTreeComponent
  ],
  declarations: [
    BusinessUnitNavigationComponent,
    BusinessStructureManagerComponent,
    BsmNodeItemComponent,
    BsmTreeGrpupComponent,
    BsmTreeItemComponent,
    LeaveBusinessStructurePopupComponent
  ],
  exports: [BusinessUnitNavigationComponent, BusinessStructureManagerComponent]
})
export class BusinessStructureSharedModule {
  static forRoot(): ModuleWithProviders<BusinessStructureSharedModule> {
    return {
      ngModule: BusinessStructureSharedModule,
      providers: [BusinessStructureService, BusinessStructureFacade]
    };
  }
}
